import './bootstrap';
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import Focus from '@alpinejs/focus'
import AlpineFloatingUI from '@awcodes/alpine-floating-ui'
import {livewire_hot_reload} from 'virtual:livewire-hot-reload'
import Toolkit from '@alpine-collective/toolkit'

livewire_hot_reload();

Alpine.plugin(Focus)
Alpine.plugin(AlpineFloatingUI)
Alpine.plugin(Toolkit)

Livewire.start()